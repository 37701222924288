import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Header section
import ScrollToTop from "./ScrollToTop";
import Loader from "./Loader";
import Header from "../containers/Header/Header";
import Footer from "../containers/Footer/Footer";

// Pages
import Home from "../containers/Pagehome/Home";
import Detail from "../containers/Pagedetail/Detail";
import Detailback from "../containers/Pagedetail/detailback";
import Trending from "../containers/PageTrending/Trendingpage";
import Aboutus from "../containers/Pageaboutus/Aboutus";
import Privacypolicy from "../containers/Pageprivacypolicy/Privacypolicy";
import Termscondition from "../containers/Pagetermscondition/Termscondition";
import Category from "../containers/PageCategory/Category";
import Contactus from "../containers/Pagecontactus/Contactus";
import Sitemap from "../containers/PageSitemap/Sitemap";
import Searchpage from "../containers/Pagesearch/Searchpage";

export const pages = [
  { path: "/", component: Home },
  { path: "/detail/:title", component: Detail }, // Updated path to use 'title'
  { path: "/preview/:title", component: Detailback },
  { path: "/About-us", component: Aboutus },
  { path: "/Trending", component: Trending },
  { path: "/Privacy-policy", component: Privacypolicy },
  { path: "/Terms-condition", component: Termscondition },
  { path: "/category/:category", component: Category },
  { path: "/Contact-us", component: Contactus },
  { path: "/Sitemap", component: Sitemap },
  { path: "/search/:query", component: Searchpage }
];

const MyRoutes = () => {
  return (
    <>
        <ScrollToTop />
        <Loader />
        <Header />
        <Routes>
          {pages.map(({ component: Component, path }, index) => {
            return <Route key={index} element={<Component />} path={path} />;
          })}
        </Routes>
        <Footer />
    </>
  );
};

function MainRoutes() {
  return (
    <Router>
        <MyRoutes />
    </Router>
  );
}

export default MainRoutes;
