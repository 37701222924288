import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// Css File
import '../../css/footer.css'

// Icons & imgeas common files
import * as Img from '../../components/Img';
import Icons from '../../components/icons';

const Footer = () => {

    const [activeIndex, setActiveIndex] = useState(null);

    const handleClick = (index) => {
        setActiveIndex(index);
    };
    return (
        <div className='footer-section padding-50'>
            <div className='container-lg'>
                <div className='footer-section-inner'>
                    <div className='footer-top'>
                        <div className='footer-left'>
                            <div className='custom-heading ch-medium'>Sign up for newsletter</div>
                            <div className='custom-text ct-small '>Sign up now and be the first to know about exclusive offers, latest fashion news & style tips!</div>
                        </div>
                        <div className='footer-right'>
                            <ul>
                                <li><Link><img src={Img.Twitter} alt='twitter' /></Link></li>
                                <li><Link><img src={Img.Instagram} alt='instagram' /></Link></li>
                                <li><Link><img src={Img.YouTube} alt='youtube' /></Link></li>
                                <li><Link><img src={Img.LinkedIn} alt='linkdin' /></Link></li>
                            </ul>
                        </div>
                    </div>

                    <div className='footer-center'>
                        {/* <label >Email</label> */}
                        <div className='custom-input'>
                            <input type="email" id="email" name="email" className='form-control' placeholder='Enter your Email' />
                            <button className='common-btn-item cbi-solid'><Icons.EnvelopeSvg /> Subscribe</button>
                        </div>
                    </div>

                    <div className='footer-bottom'>
                        <ul>
                            {[
                                { to: "/", label: "Home" },
                                { to: "/Aboutus", label: "About us" },
                                { to: "/", label: "Category" },
                                { to: "/Contact-us", label: "Contact Us" }, // assuming Contact Us route exists
                                { to: "/Privacy-policy", label: "Privacy Policy" },
                                { to: "/Terms-condition", label: "Term & Condition" },
                                { to: "/Sitemap", label: "Sitemap" },
                                // { to: "/category/politics", label: "Politics" },
                                // { to: "/", label: "Technology" },
                                // { to: "/category/sports", label: "Sports" },
                                // { to: "/", label: "Health" },
                                // { to: "/", label: "Entertainment" },
                                // { to: "/", label: "Business" },
                                // { to: "/category/market", label: "Market" },


                            ].map((item, index) => (
                                <li
                                    key={index}
                                    className={activeIndex === index ? 'active' : ''}
                                    onClick={() => handleClick(index)}
                                >
                                    <Link to={item.to}>{item.label}</Link>
                                    {index < 6 && <span>|</span>} {/* Add span separator except for the last item */}
                                </li>
                            ))}
                        </ul>

                        <div className='footer-copyright'>
                            <div className='custom-heading ch-small'>
                                © 2024, Forbes Media LLC. All Rights Reserved
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer