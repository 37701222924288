import React from 'react'
import { Link } from 'react-router-dom';

import Icons from '../../components/icons';
import * as Img from '../../components/Img';

const Fashion = () => {
    return (
        <>
            <div className='main-fashion padding-50'>
                <div className='container-lg row-gap'>
                    <div className='flex-box'>
                        <div className='custom-heading ch-medium'>
                            Fashion
                        </div>
                        {/* <Link className='custom-heading ch-small' to="/category/politics" >
                            View all <span className='icon'><Icons.ArrowlongrightSvg /></span>
                        </Link> */}
                    </div>
                    <div className="news-messsanory">
                        {/* Main Left Section */}
                        <div className="left-section">
                            <Link to="/" className='nm-inner'>
                                <img
                                    src="https://assets.vogue.in/photos/670e6761e98e1bfe6bf94a62/2:3/w_960,c_limit/SSK_9512.JPG"
                                    alt="Main Story"
                                    className="main-image"
                                />

                                <div className='nm-content'>
                                    <div className='overlay'></div>
                                    <div className="main-content">
                                        <h3>FASHION</h3>
                                        <h2>Who wore what at Vogue India's Forces of Fashion exhibition</h2>
                                        <p>By Vogue Staff</p>
                                        <span>17 October 2024</span>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        {/* Right Section */}
                        <div className="right-section">
                            <div class="grid-container">
                                <Link to="/" className="card ">
                                    <img src={Img.Fashionone} alt="Card Image" className="card-image grid-item grid-item-1" />
                                    <div className="card-content">
                                        <h4>SWAROVSKI</h4>
                                        <h3>Swarovski's new campaign featuring Ananya Panday</h3>
                                    </div>
                                </Link>

                                <Link to="/" className="card">
                                    <img src={Img.Fashiontwo} alt="Card Image" className="card-image grid-item grid-item-2" />
                                    <div className="card-content">
                                        <h4>FASHION</h4>
                                        <h3>A Diwali ball in New York: Did the fashion live up to the festive dress code?</h3>
                                        <p>By Rishna Shah</p>
                                        <span>15 October 2024</span>
                                    </div>
                                </Link>


                                <Link to="/" className="card">
                                    <img src={Img.Fashionthree} alt="Card Image" className="card-image grid-item grid-item-3" />
                                    <div className="card-content">
                                        <h4>FASHION</h4>
                                        <h3>A Diwali ball in New York: Did the fashion live up to the festive dress code?</h3>
                                        <p>By Rishna Shah</p>
                                        <span>15 October 2024</span>
                                    </div>
                                </Link>


                                <Link to="/" className="card">
                                    <img src={Img.Fashionfour} alt="Card Image" className="card-image grid-item grid-item-4" />
                                    <div className="card-content">
                                        <h4>FASHION</h4>
                                        <h3>A Diwali ball in New York: Did the fashion live up to the festive dress code?</h3>
                                        <p>By Rishna Shah</p>
                                        <span>15 October 2024</span>
                                    </div>
                                </Link>

                                <Link to="/" className="card">
                                    <img src={Img.Fashionfive} alt="Card Image" className="card-image grid-item grid-item-5" />
                                    <div className="card-content">
                                        <h4>FASHION</h4>
                                        <h3>A Diwali ball in New York: Did the fashion live up to the festive dress code?</h3>
                                        <p>By Rishna Shah</p>
                                        <span>15 October 2024</span>
                                    </div>
                                </Link>

                                <Link to="/" className="card">
                                    <img src={Img.Fashionsix} alt="Card Image" className="card-image grid-item grid-item-6" />
                                    <div className="card-content">
                                        <h4>FASHION</h4>
                                        <h3>A Diwali ball in New York: Did the fashion live up to the festive dress code?</h3>
                                        <p>By Rishna Shah</p>
                                        <span>15 October 2024</span>
                                    </div>
                                </Link>

                                <Link to="/" className="card">
                                    <img src={Img.Fashionseven} alt="Card Image" className="card-image grid-item grid-item-7" />
                                    <div className="card-content">
                                        <h4>FASHION</h4>
                                        <h3>A Diwali ball in New York: Did the fashion live up to the festive dress code?</h3>
                                        <p>By Rishna Shah</p>
                                        <span>15 October 2024</span>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Fashion