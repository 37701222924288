import React from 'react'
import { Link } from 'react-router-dom'

import Icons from '../../components/icons';
// Import images
import articalone from '../../img/news/articalone.webp';
import articaltwo from '../../img/news/articaltwo.png';
import articalthree from '../../img/news/articalthree.webp';
import articalfour from '../../img/news/articalfour.webp';

const Article = () => {
    const articles = [
        {
            imgSrc: articalone,
            category: "Weddings",
            title:
                "From a Sabyasachi sari to a Dior mini dress, this bride wore all-white couture at her Swedish-Indian wedding",
            author: "Hasina Jeelani",
            date: "20 October 2024",
        },
        {
            imgSrc: articaltwo,
            category: "Weddings",
            title: "Sorry lovers, public proposals are officially cringe",
            author: "Darshita Goyal",
            date: "16 October 2024",
        },
        {
            imgSrc: articalthree,
            category: "Sponsored",
            title:
                "Only Falguni Shane Peacock can make prêt look this good—and it's all in New Delhi",
            author: "Vanshika Jain",
            date: "10 October 2024",
        },
        {
            imgSrc: articalfour,
            category: "Weddings",
            title:
                "This bride wore an ivory pearl lehenga to her wedding on a remote ocean sandbar in the Maldives",
            author: "Elise Taylor",
            date: "8 October 2024",
        },
    ];

    return (
        <div className='main-artical padding-50'>
            <div className='container-lg row-gap'>
                <div className='flex-box'>
                    <div className='custom-heading ch-medium'>
                        More Coverstories
                    </div>
                    {/* <Link className='custom-heading ch-small' to="/category/politics" >
                        View all <span className='icon'><Icons.ArrowlongrightSvg /></span>
                    </Link> */}
                </div>
                <Link to="/" className="article-grid">
                    {articles.map((article, index) => (
                        <div className="article-card" key={index}>
                            <div className="image-container">
                                <img src={article.imgSrc} alt={article.title} />
                                {/* <div className="icon-overlay">
                                    <i className="fa fa-camera"></i>
                                </div> */}
                                <div className="overlaysummary"><div className="overlay-effect"></div><div className="overlay-text">Forbes</div></div>
                            </div>
                            <div className="article-content">
                                <h4 className="category">{article.category}</h4>
                                <h3 className="title">{article.title}</h3>
                                <p className="author">
                                    By {article.author}
                                    <br />
                                    {article.date}
                                </p>
                            </div>
                        </div>
                    ))}
                </Link>
            </div>
        </div>
    );
};

export default Article