import React from 'react'
import * as Img from '../../components/Img';
import Icons from '../../components/icons';
import { Link } from 'react-router-dom';


// Import images
import Twig from '../../img/news/Twilight.webp';
import Politicsnews from '../../img/news/politics.avif';
import Build from '../../img/news/build.avif';
import articalfour from '../../img/news/articalfour.webp';

const articles = [
    {
        imgSrc: Twig,
        category: "Weddings",
        title:
            "From a Sabyasachi sari to a Dior mini dress, this bride wore all-white couture at her Swedish-Indian wedding",
        author: "Hasina Jeelani",
        // date: "20 October 2024",
    },
    {
        imgSrc: Politicsnews,
        category: "Politics",
        title: " Why Women in Politics Secretly Love Rothy’s Shoes",
        author: "Darshita Goyal",
        // date: "16 October 2024",
    },
    {
        imgSrc: Build,
        category: "Sponsored",
        title:" How to Build a Capsule Wardrobe for Fall",
        author: "Vanshika Jain",
        // date: "10 October 2024",
    },
    {
        imgSrc: articalfour,
        category: "Weddings",
        title:
            "This bride wore an ivory pearl lehenga to her wedding on a remote ocean sandbar in the Maldives",
        author: "Elise Taylor",
        // date: "8 October 2024",
    },
];

const Coverstories = () => {
    return (

        <div className="coverstories padding-50">
                <div className='flex-box'>
                    <div className='custom-heading'>
                        Coverstories
                    </div>
                </div>

                <div className='main-artical pt-4'>
                    <div className='container-lg row-gap'>
                        <Link to="/" className="article-grid">
                            {articles.map((article, index) => (
                                <div className="article-card" key={index}>
                                    <div className="image-container">
                                        <img src={article.imgSrc} alt={article.title} />
                                        {/* <div className="icon-overlay">
                                    <i className="fa fa-camera"></i>
                                </div> */}
                                        <div className="overlaysummary"><div className="overlay-effect"></div><div className="overlay-text">Forbes</div></div>
                                    </div>
                                    <div className="article-content">
                                        <h4 className="category">{article.category}</h4>
                                        <h3 className="title">{article.title}</h3>
                                        <p className="author">
                                            By {article.author}
                                            <br />
                                            {/* {article.date} */}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </Link>
                    </div>
                </div>
        </div>
    )
}

export default Coverstories